import { FC, useMemo } from 'react';
import { Typography, Skeleton, styled } from '@mui/material';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';

import { ListItemStyled } from './NotificationRow/NotificationItem/styles';

const SkeletonStyled = styled(Skeleton)<{ $ltr?: boolean }>`
  margin-left: ${(props) => (props.$ltr ? 'unset' : 'auto')};
`;

export const SkeletonTimeline: FC<{ ltr?: boolean; height: number; arrayLength: number | undefined }> = ({
  ltr,
  height,
  arrayLength = 20,
}) => {
  const skeletonArray = Array.from({ length: arrayLength });
  const items = useMemo(
    () =>
      skeletonArray.map((_, id: number) => (
        <ListItemStyled key={id} $ltr={ltr} style={{ height }}>
          <TimelineContent>
            <Typography component='div'>
              <SkeletonStyled $ltr={ltr} variant='text' width={`${Math.floor(Math.random() * 30) + 40}%`} />
            </Typography>
            <Typography component='div' variant='body2' color='textSecondary'>
              <SkeletonStyled $ltr={ltr} variant='text' width={`${Math.floor(Math.random() * 30) + 20}%`} />
            </Typography>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot variant='outlined' />
            <TimelineConnector />
          </TimelineSeparator>
        </ListItemStyled>
      )),
    [height, ltr, skeletonArray]
  );
  return <>{items}</>;
};
