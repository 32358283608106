import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { collapsedNavSidebarWidth } from '/constants';

import { headerHeight } from '../Header/styles';

export const APP_CONTENT_PADDING_PX = 15;

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `calc(100% - ${collapsedNavSidebarWidth}px)`,
    minWidth: 0,
    flex: 1,
    minHeight: `calc(100vh - ${headerHeight}px)`,
    overflow: 'clip',
    marginTop: headerHeight,
    padding: APP_CONTENT_PADDING_PX,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      maxWidth: '100%',
    },
  },
  root: {
    display: 'flex',
  },
}));
