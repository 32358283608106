import { styled } from '@mui/material';

import { headerHeight } from '/common/Header/styles';
import { Sidebar } from '/shared/Sidebar/Sidebar';

import { collapsedNavSidebarWidth, navSidebarWidth } from './constants';

export const SidebarStyled = styled(Sidebar)<{ $isSidebarOpen: boolean }>`
  flex-shrink: 0;
  white-space: nowrap;
  width: ${navSidebarWidth}px;
  z-index: ${({ theme }) => theme.zIndex.drawer};

  ${({ $isSidebarOpen, theme }) =>
    $isSidebarOpen
      ? `
        transition: ${theme.transitions.create('width', {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeOut,
        })};
        width: ${navSidebarWidth}px;
        overflow-x: hidden;`
      : `
          overflow-x: hidden;
          transition: ${theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          })};
          width: ${collapsedNavSidebarWidth}px;
          overflow: hidden;
        `}
  & .MuiDrawer-paper {
    ${({ $isSidebarOpen, theme }) =>
      $isSidebarOpen
        ? `
        transition: ${theme.transitions.create('width', {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeOut,
        })};
        width: ${navSidebarWidth}px;
        overflow-x: hidden;`
        : `
          overflow-x: hidden;
          transition: ${theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          })};
          width: ${collapsedNavSidebarWidth}px;
          overflow: hidden;
        `}

    margin-top: ${headerHeight - 20}px;
    padding-top: 20px;
    height: calc(100% - ${headerHeight - 20}px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    left: 0 !important;
  }
`;

export const VersionSection = styled('div')`
  margin-right: 10px;
  text-align: right;
  min-height: 66px;

  &::selection {
    background: #ff5722;
    color: white;
  }

  & > span {
    opacity: 0.5;
  }
`;
