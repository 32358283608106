import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { collapsedNavSidebarWidth, navSidebarWidth } from '../NavSidebar';
import { headerHeight } from '../Header/styles';

const helperHeaderHeight = '80px';

const helperHalfHeight = '50vh';
const helperFullHeight = `calc(100vh - ${headerHeight}px)`;

const contentHalfHeight = `calc(${helperHalfHeight} - ${helperHeaderHeight})`;
const contentFullHeight = `calc(${helperFullHeight} - ${helperHeaderHeight})`;

export const useHelperStyles = makeStyles((theme: Theme) => ({
  helper: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1101,
    borderRadius: theme.spacing(0.5),
    margin: '0 1px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
  },
  sidebarOpen: {
    left: `${navSidebarWidth}px`,
  },
  sidebarClosed: {
    left: `${collapsedNavSidebarWidth}px`,
  },
  helperCard: {
    boxShadow: 'none',
  },
  helperFullHeight: {
    height: helperFullHeight,
    transition: `${theme.transitions.create('height', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    })} !important`,
  },
  helperHalfHeight: {
    height: helperHalfHeight,
    transition: `${theme.transitions.create('height', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    })} !important`,
  },
  header: {
    height: helperHeaderHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
  },
  content: {
    paddingTop: 0,
    overflow: 'auto',
  },
  contentFullHeight: {
    height: contentFullHeight,
  },
  contentHalfHeight: {
    height: contentHalfHeight,
  },
}));
